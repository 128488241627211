.accordianList {
  font-size: 80%;
}

.accordianTitle {
  font-variation-settings: "wght" 500;
  /* font-size: 90%; */
}

/* .accordianTitle:active {
  background: var(--ion-color-light-tint);
  outline: none;
} */

.ripple {
  background: var(--ion-background-color);
  background-position: center;
  transition: background 0.8s;
}
.ripple:active {
  background-color: var(--ion-color-light-tint);
  background-size: 100%;
  transition: background 0s;
}

/* .selectItem:active {
  background: var(--ion-color-light-tint);
  outline: none;
} */

.accordianSubTitle {
  font-variation-settings: "wght" 600;
}

/* .accordianButton {
  transform: scale(0.8);
  transform-origin: 0 0;
} */

ion-list ion-item:last-of-type {
  --inner-border-width: 0px;
}
