.zydeu {
  font-size: 150%;
  font-variation-settings: "wght" 320;
  z-index: 1;
  position: absolute;
  display: inline-block;
  transform: scale(0.9, 1);
  transform-origin: 0 0;
}

.zytieFaewe {
  z-index: 2;
}

.dictResults {
  margin-left: 0.5ex;
  border: solid var(--ion-color-medium-shade);
  border-width: 0 0 0 1px;
  padding-left: 0.5ex;
}

.lenyoe {
  font-size: 80%;
  font-variation-settings: "wght" 550;
}

.kaseh {
  /* color: var(--ion-color-dark-tint); */
  font-size: 85%;
  letter-spacing: -0.05em;
  font-variation-settings: "wght" 475;
  display: inline-block;
  transform: scale(0.72, 1);
  transform-origin: 0 0;
  width: 138.9%;
}

.jiansy {
  font-variation-settings: "wght" 550;
}

.zahsy {
  color: var(--ion-color-dark-tint);
  font-variation-settings: "wght" 380;
}

.yithi {
  font-size: 75%;
  font-variation-settings: "wght" 350;
}

.wugniuEntry {
  overflow: visible;
}

.vucihku {
  font-variation-settings: "wght" 350;
}

ion-list ion-item:last-of-type {
  --inner-border-width: 0px;
}

.sc-ion-label-ios-h {
  font-size: 100%;
}

.sc-ion-label-ios-s {
  font-size: 100%;
}
